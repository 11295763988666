import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  createCheckoutSession,
  enrollToCourse,
  getAllStripeProducts,
  requestAccessToCourse,
} from '../../../hooks/utils/courseDetail';
import { useTranslation } from 'react-i18next';
import IblSpinLoader from '../../../loaders/iblSpinLoader';
import { getTenant, getTenants } from '../../../hooks/helpers';

const CourseEnrollmentButton = ({
  enrollmentStatus,
  courseID,
  courseName,
  coursePrice,
  enrollmentStartDate,
  coursePlatform,
}) => {
  const [buttonContent, setButtonContent] = useState(null); // State to hold button content
  const [isInTenant, setIsInTenant] = useState(false);
  const [isEligible, setIsEligible] = useState(enrollmentStatus.is_eligible);
  const [courseEnrollmentStarted, setCourseEnrollmentStarted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsInTenant(getTenants().findIndex((tenant) => tenant !== 'main') !== -1);
    setCourseEnrollmentStarted(new Date() - new Date(enrollmentStartDate));
  }, []);

  useEffect(() => {
    setButtonContent(
      <Link className="button-5 w-button" to="#">
        {/* {t('Enroll')} */}
        <IblSpinLoader color="#fff" size="25" />
      </Link>
    );
    if (
      process.env.REACT_APP_ENABLE_COURSE_ELIGIBILITY_LICENSE_CHECK === 'true'
    ) {
      if (isInTenant && courseEnrollmentStarted && isEligible) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (isInTenant && courseEnrollmentStarted && !isEligible) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={_requestCourseAccess}
          >
            {t('Request Access')}
          </Link>
        );
      } else if (isInTenant && !courseEnrollmentStarted && isEligible) {
        setButtonContent(
          <Link className="button-5 w-button" to={`#`}>
            {t('Enroll - Course Starting Soon')}
          </Link>
        );
      } else if (isInTenant && !courseEnrollmentStarted && !isEligible) {
        setButtonContent(
          <Link className="button-5 w-button" to={`#`}>
            {t('Request Access - Course Starting Soon')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        courseEnrollmentStarted &&
        isEligible
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        courseEnrollmentStarted &&
        !isEligible
      ) {
        setButtonContent(
          <Link className="button-5 w-button" to={`#`}>
            {t('Request Access')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        !courseEnrollmentStarted &&
        isEligible
      ) {
        setButtonContent(
          <Link className="button-5 w-button" to={`#`}>
            {t('Enrolled - Course Starting Soon')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        !courseEnrollmentStarted &&
        !isEligible
      ) {
        setButtonContent(
          <Link className="button-5 w-button" to={`#`}>
            {t('Request Access - Course Starting Soon')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link className="button-5 w-button" to="#">
            <IblSpinLoader color="#fff" size="25" />
          </Link>
        );
      }
    } else {
      const isEnrolled = enrollmentStatus.is_enrolled;
      const invitationOnly = enrollmentStatus.invitation_only;
      if (isEnrolled) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (invitationOnly) {
        setButtonContent(
          <a className="button-5 w-button disabled" href="#">
            {t('Invitation Only')}
          </a>
        );
      } else if (coursePrice && coursePrice !== 0 && coursePrice !== 'Free') {
        setButtonContent(
          <Link
            className="button-5 w-button"
            onClick={() => createCheckoutSession(courseID)}
          >
            {t('Buy Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={handleEnrollmentBtnClick}
          >
            {t('Enroll')}
          </Link>
        );
      }
    }
  }, [isInTenant, isEligible, courseEnrollmentStarted]);

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const productData = await getAllStripeProducts(courseName, courseID);
      if (productData?.price_id) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            onClick={() =>
              createCheckoutSession(productData?.price_id, productData?.mode)
            }
          >
            {t('Buy Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={handleEnrollmentBtnClick}
          >
            {t('Enroll')}
          </Link>
        );
      }
    } catch (error) {
      console.error('Error fetching Stripe products:', error);
      setButtonContent(null); // Set button content to null in case of error
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true') {
      fetchData();
    } else {
      setButtonContent(
        <Link
          className="button-5 w-button"
          to="#"
          onClick={handleEnrollmentBtnClick}
        >
          Enroll
        </Link>
      );
    }
    return () => {};
  }, [courseName, courseID]); // Dependencies for useEffect

  const handleEnrollmentBtnClick = () => {
    enrollToCourse(enrollToCourseCallback, courseID);
  };

  const _requestCourseAccess = () => {
    requestAccessToCourse(requestAccessToCourseCallback, courseID);
  };

  const requestAccessToCourseCallback = () => {
    alert(t('Request Access to Course Successful'));
  };

  const enrollToCourseCallback = (data) => {
    if (data && data.created) {
      navigate(`/courses/${courseID}/course`);
    } else {
      alert(t('Course Enrollment Failed, Something went wrong'));
    }
  };

  return buttonContent;
};

export default CourseEnrollmentButton;
