import CourseIframeSecondaryMenu from '../../../components/CourseIframeSecondaryMenu/CourseIframeSecondaryMenu';
import CourseIframeSideMenue from '../../../components/CourseIframeSideMenue';
import CourseIframeSequenceMenu from '../../../components/CourseIframeSequenceMenu';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
  IBLSpinLoader,
  useCourseIframe,
  Error,
} from '@iblai/ibl-web-react-common';
import './CourseIframeCourseContentView.css';
import { getParameterByName } from '../../../hooks/helpers';
import {
  getFirstAvailableUnit,
  getIframeURL,
} from '../../../hooks/utils/iframe';
import { Link } from 'react-router-dom';
import { IframeContext } from '../context/iframe-context';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const CourseIframeCourseContentView = () => {
  const { courseID } = useParams();
  const [
    courseDetails,
    courseLoaded,
    courseCompletion,
    courseCompletionLoaded,
    courseGradeProgress,
    courseGradeProgressLoaded,
  ] = useCourseIframe(courseID);
  const [isCourseContentAvailable, setIsCourseContentAvailable] =
    useState(false);
  const [displayCourseNotAvailableError, setDisplayCourseNotAvailableError] =
    useState(false);
  const [edxIframe, setEdxIframe] = useState('');
  const [edxIframeLoading, setEdxIframeLoading] = useState(false);
  const [unitIdFromUrl, setUnitIdFromUrl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [refresher, setRefresher] = useState(null)
  const MOBILE_TARGET = 480

  const [isMobile, setIsMobile] = useState(false)

  const handleMobileResize = () => {
    setIsMobile(window.innerWidth <= MOBILE_TARGET)
  }

  useEffect(() => {
    handleMobileResize()
    window.addEventListener("resize", handleMobileResize)
    return ()=>{
      window.removeEventListener("resize", handleMobileResize)
    }
  }, []);

  useEffect(() => {
    if (courseLoaded) {
      try {
        setIsCourseContentAvailable(_isCourseContentAvailable(courseDetails));
      } catch (e) {
        setDisplayCourseNotAvailableError(true);
      }
    }
  }, [courseLoaded]);

  function _isCourseContentAvailable(courseDetails) {
    const unitId =
      getParameterByName('unit_id') || getFirstAvailableUnit(courseDetails)?.id;
    if (!unitId) return false; // Return false if no unitId is available

    // Check if any childItem within any item has the id matching unitId
    const hasUnitId = courseDetails?.children?.some(
      (item) =>
        Array.isArray(item.children) &&
        item.children.some((childItem) => childItem.id === unitId)
    );
    return !hasUnitId;
  }

  const changeIframeLoadingStatus = (status) => {
    setEdxIframeLoading(status);
  };

  const handleCollapseClick = (status) => {
    setIsExpanded(status);
  };

  const handleIframeUrl = (url) => {
    setEdxIframe(url);
  };

  const setHighlightOnMenu = () => {
    const unitId =
      getParameterByName('unit_id') || getFirstAvailableUnit(courseDetails).id;
    setUnitIdFromUrl(unitId);
  };

  const handleOutlineClick = (event) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    const currentUnitId = getParameterByName('unit_id');

    const firstUnitId = getFirstAvailableUnit(courseDetails).id;

    // If there's no unit_id in the URL and this is  the first element, return early
    if (!currentUnitId && dataId === firstUnitId) {
      return;
    }
    if (dataId !== getParameterByName('unit_id')) {
      const newURL = `/courses/${courseID}/course?unit_id=${dataId}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setHighlightOnMenu();
      console.log('course details ', courseDetails);
      getIframeURL(courseID, courseDetails, handleIframeUrl);
    }
  };

  return courseLoaded || displayCourseNotAvailableError ? (
    courseLoaded && !displayCourseNotAvailableError ? (
      courseDetails && courseDetails?.children ? (
        <>
          <CourseIframeSecondaryMenu
            courseData={courseDetails}
            edxIframe={edxIframe}
            handleOutlineClick={handleOutlineClick}
          />
          <CourseIframeSideMenue
            courseData={courseDetails}
            setEdxIframe={setEdxIframe}
            changeIframeLoadingStatus={changeIframeLoadingStatus}
            unitIdFromUrl={unitIdFromUrl}
            setUnitIdFromUrl={setUnitIdFromUrl}
            handleMenuCollapse={handleCollapseClick}
            isMobile={isMobile}
            refresher={refresher}
          />
          <div
            className="inside_page"
            style={{ marginLeft: isMobile ? "0px" :  (isExpanded ? '316px' : '40px') }}
          >
            <CourseIframeSequenceMenu
              courseData={courseDetails}
              setEdxIframe={setEdxIframe}
              changeIframeLoadingStatus={setEdxIframeLoading}
              setUnitIdFromUrl={setUnitIdFromUrl}
            />

            <IframeContext.Provider
              value={{
                edxIframeLoading,
                isCourseContentAvailable,
                setEdxIframeLoading,
                edxIframe,
                courseData:courseDetails,
                setEdxIframe,
                setRefresher
              }}
            >
              <Outlet />
            </IframeContext.Provider>

            {/* <div className="html-embed-19 w-embed w-iframe">
              {edxIframeLoading && isCourseContentAvailable && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <IBLSpinLoader />
                </div>
              )}
              {isCourseContentAvailable ? (
                <iframe
                  id="edx-iframe"
                  title="Course InnerWare"
                  sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
                  frameBorder={0}
                  src={edxIframe}
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 100px - 62px)',
                  }}
                  allowFullScreen
                  onLoad={() => setEdxIframeLoading(false)}
                ></iframe>
              ) : (
                <>
                  <div style={{ textAlign: 'center' }}>
                    There is no content here
                  </div>
                </>
              )}
            </div> */}
          </div>
        </>
      ) : (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\n.no-course-data-box {\n    margin-top: 24px;\n    padding: 24px;\n    border-width: 1px;\n    border-style: solid;\n    border-color: rgb(221, 225, 230);\n    border-radius: 4px;\n    height: 238px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    margin-left: 24px;\n    margin-right: 24px;\n}\n\n.no-course-flex img {\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 16px;\n}\n\n',
            }}
          />

          <div className="no-course-data-cont">
            <div className="no-course-data-box">
              <div className="no-course-flex">
                <img
                  style={{ width: 40, height: 40 }}
                  src="/images/open-book.png"
                  alt=""
                />
                <div>No content for this course yet.</div>
              </div>
            </div>
          </div>
        </>
      )
    ) : (
      displayCourseNotAvailableError && (
        <Error
          errorCode={404}
          header="Whoops! "
          message="The course doesn’t appear to be published."
          subMessage="Please make sure that the course is published and then try again."
        >
          <Link to={'/profile/courses'} style={{ textDecoration: 'none' }}>
            &nbsp;My Courses
          </Link>
        </Error>
      )
    )
  ) : (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <IBLSpinLoader size={40} />
    </div>
  );
};

export default CourseIframeCourseContentView;
